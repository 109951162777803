<template>
  <div class="app-aside">
    <!-- 折叠按钮 -->
    <div class="avatar_box" >
      <i :class="collapseIconClass" @click="handleMenuStatus"></i>
    </div>
    <el-menu
      :collapse="isCollapse"
      :router="true"
      unique-opened
      class="el-menu-vertical-demo"
      background-color="#18181A" s="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b">

      <!-- logo图片 -->
      <div class="logo logo-class">
        <img src="@/assets/testplatform copy.png">
        <div style=" margin-left: 5px; margin-top: 5%; color: aliceblue;"><h2>测试平台</h2></div>
      </div>
      <!-- 菜单列表 -->
      <el-submenu index="/projectOrModule" :split="true">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span slot="title">项目/模块管理</span>
        </template>
        <el-menu-item index="/project">项目管理</el-menu-item>
        <el-menu-item index="/module">模块管理</el-menu-item>
      </el-submenu>

      <el-submenu index="/apiTest">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span slot="title">API测试</span>
        </template>
        <el-menu-item index="/apiBaseInfo">接口管理</el-menu-item>
        <el-menu-item index="/apiCase">接口用例</el-menu-item>
        <el-menu-item index="/apiEnv">环境管理</el-menu-item>
        <el-menu-item index="/apiEnvVar">环境变量管理</el-menu-item>
      </el-submenu>

      <el-submenu index="/webUiTest">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span slot="title">WebUi测试</span>
        </template>
        <el-menu-item index="/UiPage">页面管理</el-menu-item>
        <el-menu-item index="/uiElement">元素管理</el-menu-item>
        <el-menu-item index="/uiAction">动作管理</el-menu-item>
        <el-menu-item index="/uiStep">步骤管理</el-menu-item>
        <el-menu-item index="/uiCase">用例管理</el-menu-item>
        <el-menu-item index="/uiEnv">环境管理</el-menu-item>
      </el-submenu>

      <el-menu-item index="/cron">
        <i class="el-icon-truck"></i>
        <span slot="title">定时任务</span>
      </el-menu-item>

      <el-submenu index="/testTools">
        <template slot="title">
          <i class="el-icon-s-tools"></i>
          <span slot="title">测试工具</span>
        </template>
        <el-menu-item index="/helpTools">
          <i class="el-icon-s-help"></i>
          <span slot="title">常用工具入口</span>
        </el-menu-item>
        <el-menu-item index="/productionTools">
          <i class="el-icon-s-cooperation"></i>
          <span slot="title">生产工具</span>
        </el-menu-item>
      </el-submenu>

      <el-submenu index="charts">
        <template slot="title">
          <i class="el-icon-pie-chart"></i>
          <span slot="title">数据报表</span>
        </template>
        <el-menu-item index="/apiReport">API测试报告</el-menu-item>
        <el-menu-item index="/webUIReport">WebUI测试报告</el-menu-item>
        <el-menu-item index="/defectMetricAnalysis">缺陷度量分析</el-menu-item>
        <el-menu-item index="/chart/useFrequency">使用频次报表</el-menu-item>
        <el-menu-item index="/chart/report">Echarts报告</el-menu-item>

        <!-- <el-menu-item index="/chart/useFrequency"> -->
          <!-- <i class="el-icon-pie-chart" style="margin: 0 5px"></i> -->
          <!-- <span slot="title"> -->
            <!-- <router-link to="/chart/useFrequency">使用频次报表</router-link> -->
            <!-- Echarts报告 -->
          <!-- </span> -->
        <!-- </el-menu-item> -->

        <!-- <el-menu-item index="/chart/report"> -->
          <!-- <i class="el-icon-pie-chart" style="margin: 0 5px"></i> -->
          <!-- <span slot="title"> -->
            <!-- <router-link to="/chart/report">Echarts报告</router-link> -->
            <!-- Echarts报告 -->
          <!-- </span> -->
        <!-- </el-menu-item> -->

      </el-submenu>

      <el-submenu index="system">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span slot="title">系统管理</span>
        </template>
        <el-menu-item index="/system/account">账户管理</el-menu-item>
        <el-menu-item index="/system/role">角色管理</el-menu-item>
        <el-menu-item index="/system/menu">菜单管理</el-menu-item>
      </el-submenu>

    </el-menu>
  </div>
</template>

<script>
import niceIcon from '@/assets/testplatform copy.png'
export default {
  name: 'AppAside',
  data () {
    return {
      collapseIconClass: 'el-icon-d-arrow-left',
      isCollapse: false,
      niceIcon: niceIcon
    }
  },
  methods: {

    // 折叠侧边栏
    handleMenuStatus () {
      this.isCollapse = !this.isCollapse
      if (this.collapseIconClass === 'el-icon-d-arrow-left') {
        this.$emit('change-aside-width', '64px');
        this.collapseIconClass = 'el-icon-d-arrow-right'
      } else {
        this.$emit('change-aside-width', '200px');
        this.collapseIconClass = 'el-icon-d-arrow-left';
      }
      console.log('dsdsd')
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-menu {
    height: 100vh;
    border-right: 0;
    max-height: 100%;
    overflow-y: auto;
  }
  .router-link-active {
    text-decoration: none;
    color: #FFD04B;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .avatar_box {
    position: absolute;
    inset-block-start: 20px;
    inset-inline-end: -13px;
    z-index: 101;
    height: 18px;
    width: 18px;
    text-align: center;
    border-radius: 50%;
    // border: 1px solid #eee;
    // box-shadow: 0 0 10px #ddd;
    // left: 18%;
    transform: translate(0, 0); /* 初始的平移值 */
    transition: transform 0.3s; /* 过渡时间为0.3秒 */
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; // 将鼠标光标形状设置为指针样式
    color: white;
    background-color: #303133;
  }
  .logo-class {
    height: 59px;
    background-color: #18181A;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img {
    width: 50px;
    height: 50px;
    margin-left: 2px;
    padding: 10px 8px 1px 5px;
    border-radius: 50%;
  }
  // img {
  //   // position: absolute; /* 设置图片为绝对定位 */
  //   // top: 9%; /* 将图片顶部与容器中心对齐 */
  //   // left: 24%; /* 将图片左侧与容器中心对齐 */
  //   // transform: translate(50%, -50%); /* 将图片向左上方平移自身宽度和高度的一半 */
  //   // max-width: 100%; /* 设置图片最大宽度为容器宽度 */
  //   // max-height: 100%; /* 设置图片最大高度为容器高度 */
  //   // transform: scale(5);

  //   width: 100%;
  //   height: 100%;
  //   max-width: 100%;
  //   max-height: 59px;
  //   object-fit: cover;
  //   transform: scale(1.8);
  //   margin-top: 8px;
  //   padding-right: 20px;
  // }
</style>
