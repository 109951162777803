<template>
  <div class="layout">
    <el-container>
      <el-aside :width="asideWidth">
        <app-aside @change-aside-width="updateAsideWidth"></app-aside>
      </el-aside>
      <el-container>
        <el-header>
          <app-header></app-header>
        </el-header>
        <el-main>
          <!-- 路由出口 -->
          <router-view></router-view>
          <!-- #示例代码如下： -->
          <!-- <div style="font-size: 12px;">
            <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023025646号</a>
          </div>
          <div style="font-size: 12px;">
            <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023025646号-1</a>
          </div> -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import AppAside from '@/views/layout/components/AppAside.vue'
import AppHeader from '@/views/layout/components/AppHeader.vue'
export default {
  name: 'layout',
  data () {
    return {
      asideWidth: '200px'
    }
  },
  methods: {
    updateAsideWidth (nweWidth) {
      this.asideWidth = nweWidth
    }
  },
  components: {
    AppAside,
    AppHeader
  }
}
</script>

<style lang="scss" scoped>
  .el-container {
    height: 100vh;
    background: #ffffff; //lightcyan;
  }
  .el-aside {
    height: 100%;
    background-color: #ffffff; //lightcoral;
    position: relative;
    overflow: hidden;
  }
  .el-header {
    border-bottom: 1px solid #ccc;
    padding: 0 20px 0 0;
  }
  .el-main {
    background-color: #f8f8f9; //lightpink;
  }
</style>
