<template>
  <div class="app-header">
    <div>
      <!-- <h2>
        <router-link class="router-link-style" to="/">测试平台</router-link>
      </h2> -->
    </div>
    <el-dropdown>
      <span class="el-dropdown-link">
        <el-avatar
          shape="circle"
          size="medium"
          src="https://wimg.588ku.com/gif620/21/08/10/3b2d09fdbec052fbdf42f830105bd86d.gif">
        </el-avatar>
        <!-- src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"> -->
        <!-- src="https://wimg.588ku.com/gif620/20/12/22/679002b6f14d97832efa6b3a266d1dbd.gif"> -->
        <!-- src="https://wimg.588ku.com/gif620/20/12/18/0f10ea9375557ff31cef531bbd658c2e.gif"> -->
        <!-- src="https://wimg.588ku.com/gif620/21/08/10/3b2d09fdbec052fbdf42f830105bd86d.gif"> -->
        <!-- src="https://wimg.588ku.com/gif620/20/12/28/1a6c3c6c6cedc7311f98464188fbaed4.gif"> -->
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>{{ userInfo.username }}</el-dropdown-item>
        <el-dropdown-item divided>
          <el-button type="text" @click="handleLogout">退出登录</el-button>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { getUserInfo } from '@/services/user.js'
export default {
  name: 'AppHeader',
  data () {
    return {
      userInfo: { username: window.localStorage.getItem('username') } || null
    }
  },
  created () {
    this.loadUserInfo()
  },
  methods: {
    async loadUserInfo () {
      const queryObj = {
        username: this.userInfo.username,
        query_type: 'search_user'
      }
      const { data: res } = await getUserInfo(queryObj)
      this.userInfo = res.data[0]
      console.log(res, '<--用户信息')
      console.log(this.userInfo.nick_name, '<--用户昵称')
    },
    handleLogout () {
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 消除用户登录之后的保存的用户凭据信息
        this.$store.commit('setUser', null)
        // 退出登录后，跳转登录页面
        this.$router.push('/login')
        this.$message({
          type: 'success',
          message: '退出登录成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出'
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-header {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-dropdown-link {
    display: flex;
    align-items: center;
  }
  .router-link-style {
    text-decoration: none;
    color: black;
  }
</style>
